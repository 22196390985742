<template>
  <div id="navbar-detail">
    <el-dialog :title="'【' + titleName + '】'" :close-on-click-modal="false" width="85%" :visible.sync="visible" :modal-append-to-body='false'>
      <div class="searchBox">
        <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()" size="small">
        <el-form-item label="证件对象">
           <el-select class="item-choose" v-model="dataForm.cardObject" style="width: 100%;" filterable clearable placeholder="请选择" @change="getDataList">
              <el-option label="请选择" value=""></el-option>
              <el-option
                v-for="item in dictTypeMap.card_type"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="证件类型">
            <el-input v-model="dataForm.cardTypeName" placeholder="证件类型"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button @click="getDataList()" type="primary">查询</el-button>
        </el-form-item>
      </el-form>
      </div>
      <div class="tableBox">
        <el-table height="55vh" :row-class-name="tableRowClassName" @row-click="rowClick" :row-style="selectedHighlight"  :data="dataList" border size="small" v-loading="dataListLoading" @selection-change="selectionChangeHandle" style="width: 100%;">
          <el-table-column prop="cardObject" label="证件对象"  :formatter="cardType" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column prop="name" label="对象名称" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column prop="cardTypeName" label="证件类型">
        </el-table-column>
        <el-table-column prop="status" label="证件状态">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.status === 3" size="small" type="danger">已过期</el-tag>
            <el-tag  v-else-if="scope.row.status === 2" size="small" type="success">即将过期</el-tag>
            <el-tag v-else size="small" type="success">正常</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="cardBeginTime" label="有效开始日期" width="100" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column prop="cardEndTime" label="证件失效日期" width="100" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column prop="cardNo" label="证件编码">
        </el-table-column>
        <el-table-column prop="archiveNo" label="档案编码">
        </el-table-column>
        <el-table-column prop="createCardCost" label="办证费用">
        </el-table-column>
        <el-table-column label="附件" prop="" width="100">
          <template slot-scope="scope">
            <span @click="viewPhoto(scope.row)" style="color: blue;cursor:pointer"  v-if="scope.row.fileNum>0">查 看</span>
            <span v-else>
              无
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="remark" label="备注">
        </el-table-column>
        <el-table-column prop="createCardTime" label="办证日期" width="100" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column prop="createName" label="创建人">
        </el-table-column>
        </el-table>
        <el-pagination
          @size-change="sizeChangeHandle"
          @current-change="currentChangeHandle"
          :current-page="pageIndex"
          :page-sizes="[20, 50, 100]"
          :page-size="pageSize"
          :total="totalPage"
          layout="total, sizes, prev, pager, next, jumper">
        </el-pagination>
      </div>
      <span slot="footer" class="dialog-footer">
        <!-- <el-button type="primary" @click="submit">确定</el-button> -->
        <el-button @click="visible = false">关闭</el-button>
      </span>
    </el-dialog>
    <!-- 查看图片 -->
    <view-image ref="viewPhoto"></view-image>
  </div>
</template>

<script>
import viewImage from '@/views/commonPop/viewImage'
export default {
  data () {
    return {
      dataForm: {
        cardObject: null,
        cardTypeName: null,
        status: null
      },
      visible: false,
      fontRate: {
        OTHER_RATE: 1.5 // 除汉字和数字以外的字符的比率
      },
      dataList: [],
      pageIndex: 1,
      pageSize: 20,
      totalPage: 0,
      getIndex: -1,
      dataListLoading: false,
      dataListSelections: [],
      addOrUpdateVisible: false,
      detailVisible: false,
      imageTypeList: [3000, 3001],
      titleName: ''
    }
  },
  components: {
    viewImage
  },
  activated () {
    this.getDataList()
  },
  computed: {
    dictTypeMap: {
      get () { return this.$store.state.common.dictTypeMap }
    }
  },
  methods: {
    /**
     * 点击表格变色start
     */
    selectedHighlight ({ row, rowIndex }) {
      if ((this.getIndex) === rowIndex ) {
        return {
          'background-color': 'rgb(250, 195, 100)'
        }
      }
    },
    // 如果为已取消时，该行置灰
    tableRowClassName ({ row, rowIndex }) {
      row.index = rowIndex;
      if (row.status === 12) {
        return 'cancel-row'
      }
      return ''
    },
    rowClick (row) {
      this.getIndex = row.index
    },
    /**
     * 点击表格变色end
     */
    // 查看照片
    viewPhoto (row) {
      this.$refs.viewPhoto.init(row.id, this.imageTypeList)
    },
    // 绑定驾驶员
    bindDriver (row) {
      this.$refs.bind.init(row)
    },
    // 解绑驾驶员
    untyingDriver (row) {
      this.$refs.untying.init(row)
    },
    init (typeName) {
      this.visible = true
      this.titleName = typeName
      // 即将到期：2，已到期：3
      if(typeName == '即将到期') {
        this.dataForm.status = 2
      } else {
        this.dataForm.status = 3
      }
      this.getDataList()
    },
    // 获取数据列表
    getDataList () {
      this.dataListLoading = true
      this.$http({
        url: this.$http.adornUrl('/cardWarn/findList'),
        method: 'get',
        params: this.$http.adornParams({
          'page': this.pageIndex,
          'limit': this.pageSize,
          'status': this.dataForm.status,
          'cardTypeName': this.dataForm.cardTypeName,
          'cardObject': this.dataForm.cardObject
        })
      }).then(({data}) => {
        if (data) {
          this.dataList = data.list
          this.totalPage = data.total
        } else {
          this.dataList = []
          this.totalPage = 0
        }
        this.dataListLoading = false
      })
    },
    // 每页数
    sizeChangeHandle (val) {
      this.pageSize = val
      this.pageIndex = 1
      this.getDataList()
    },
    // 当前页
    currentChangeHandle (val) {
      this.pageIndex = val
      this.getDataList()
    },
    // 多选
    selectionChangeHandle (val) {
      this.dataListSelections = val
    },
    // 详情
    detail (id) {
      this.detailVisible = true
      this.$nextTick(() => {
        this.$refs.detail.init(id)
      })
    },
    // 新增 / 修改
    addOrUpdateHandle (todo, id) {
      this.addOrUpdateVisible = true
      this.$nextTick(() => {
        this.$refs.addOrUpdate.init(todo, id)
      })
    },
    // 禁用或启用
    disableOrEnable (row) {
      let title = row.status === 1 ? '禁用' : '启用'
      this.$confirm('确定对【' + row.vehiclePlate + '】进行【' + title + '】操作?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http({
          url: this.$http.adornUrl('/vehicleInfo/updateStatus'),
          method: 'post',
          data: {
            id: row.id,
            status: row.status === 1 ? 2 : 1
          }
        }).then(({data}) => {
          if (data) {
            this.$message({
              message: '操作成功',
              type: 'success',
              duration: 1500,
              onClose: () => {
                this.getDataList()
              }
            })
          } else {
            this.$message.error('操作失败')
          }
        })
      }).catch(() => {})
    },
    // 删除
    deleteHandle (id) {
      let ids = id ? [id] : this.dataListSelections.map(item => {
        return item.id
      })
      this.$confirm(`确定对[id=${ids.join(',')}]进行[${id ? '删除' : '批量删除'}]操作?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http({
          url: this.$http.adornUrl('/sys/user/delete'),
          method: 'post',
          data: this.$http.adornData(ids, false)
        }).then(({data}) => {
          if (data && data.code === 0) {
            this.$message({
              message: '操作成功',
              type: 'success',
              duration: 1500,
              onClose: () => {
                this.getDataList()
              }
            })
          } else {
            this.$message.error(data.msg)
          }
        })
      }).catch(() => {})
    }
  }
}
</script>
<style lang="less">
#navbar-detail {
  .el-dialog {
    top: 50%;
    margin-top: 0 !important;
    transform: translate(0, -50%);
  }
  .el-dialog__header {
    padding: 10px 0px;
  }
  .el-dialog__body {
    padding: 0px 10px;
    .el-table .DisabledSelection .cell .el-checkbox {
      display: none;
      position: relative
    }
    // .el-table .DisabledSelection .cell:before {
    //   content: '选择';
    //   position: absolute;
    // }
  }
  .el-pagination {
    margin-top: 5px;
    span {
      font-size: 12px;
    }
    .el-input__inner {
      height: 22px !important;
    }
  }
  .el-dialog__footer {
    padding: 10px 10px;
    button.el-button {
      height: 26px;
      line-height: 0;
    }
  }
}
</style>
